import React, { useState } from 'react';
import styled from '@emotion/styled';

import { PublicShop } from '@typings/PublicShop';
import OtpInput from 'react-otp-input';
import { sendCode } from '../api';
import { PrimaryButton, SecondaryButton, AlertContainer, OtpContainer } from '../components';

interface VerificationCodeStepProps {
  shop: PublicShop;
  optInValues: {
    phone: string;
    email: string;
    country: string;
  };
  setStep?: (step) => void;
  onVerify?: (smsCode: string) => Promise<Response>;
}

export const VerificationCodeStep = ({
  shop,
  setStep,
  onVerify,
  optInValues,
}: VerificationCodeStepProps) => {
  const [loading, setLoading] = useState(false);
  const [lockResend, setLockResend] = useState(false);
  const [beginRemoveLock, setBeginRemoveLock] = useState(false);
  const [code, setCode] = useState('');
  const [codeFailed, setCodeFailed] = useState(false);
  const [alertContent, setAlertContent] = useState('we_sent');
  const RESEND_LOCK_MS = 60000;

  const handleSubmit = async () => {
    if (!code || code.length !== 4) {
      return setCodeFailed(true);
    }
    try {
      setLoading(true);
      await onVerify(code);
      setStep('thank_you');
    } catch (error) {
      const errorBody = error.response.data;
      if (errorBody.error === 'too_many') {
        setAlertContent('too_many');
      } else {
        setAlertContent('try_again');
      }
      setCodeFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    setAlertContent('new_code');
    setLockResend(true);
    await sendCode(shop?.shopifyDomain, optInValues.phone, optInValues.email, optInValues.country);
    setBeginRemoveLock(true);
    setTimeout(() => {
      setBeginRemoveLock(false);
      setLockResend(false);
    }, RESEND_LOCK_MS);
  };

  function handleOtpChange(e) {
    setCodeFailed(false);
    setCode(e);
  }

  function formatPhoneNumber(phone) {
    // this only works because we only have canadian and american phone numbers
    // need more robust solution when we go international
    const areaCode = phone.slice(1, 4);
    const firstThree = phone.slice(4, 7);
    const lastFour = phone.slice(7, 19);
    return `+1 (${areaCode}) ${firstThree}-${lastFour}`;
  }

  const alertContentWeSent = `We sent a verification code to ${formatPhoneNumber(
    optInValues.phone
  )}`;

  const alertContentNewCode = `New code sent to ${formatPhoneNumber(optInValues.phone)}`;

  const alertContainerTryAgain = `Invalid SMS code. Please try again.`;

  const alertContainerTooMany = (
    <div>
      Too many attempts.
      <br />
      Please resend code and try again.
    </div>
  );

  function renderAlertContent() {
    switch (alertContent) {
      case 'too_many':
        return alertContainerTooMany;
      case 'try_again':
        return alertContainerTryAgain;
      case 'new_code':
        return alertContentNewCode;
      default:
        return alertContentWeSent;
    }
  }

  function submitButtonText() {
    if (loading) {
      return 'Verifying...';
    }
    if (alertContent === 'try_again' || alertContent === 'too_many') {
      return 'Try Again';
    }
    return 'Verify';
  }

  return (
    <>
      <AlertContainer>{renderAlertContent()}</AlertContainer>
      <div>
        <OtpContainer>
          <OtpInput
            value={code}
            isInputNum
            inputStyle="inputStyle"
            onChange={handleOtpChange}
            numInputs={4}
            hasErrored={codeFailed}
            errorStyle="error"
            shouldAutoFocus
          />
        </OtpContainer>
      </div>
      <ButtonContainer>
        <div className="btn-wrap">
          <SecondaryButton
            type="button"
            block
            onClick={resendCode}
            disabled={lockResend}
            className={`${lockResend ? 'fill' : ''} ${beginRemoveLock ? 'remove-fill' : ''}`}
          >
            Resend Code
          </SecondaryButton>
        </div>
        <div className="btn-wrap">
          <SubmitButton onClick={handleSubmit} block disabled={alertContent === 'too_many'}>
            {submitButtonText()}
          </SubmitButton>
        </div>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  .btn-wrap {
    flex: 0 0 calc(50% - 8px);
  }
`;

const SubmitButton = styled(PrimaryButton)`
  &[disabled] {
    background-color: var(--cta-button-color) !important;
    color: var(--cta-button-font-color) !important;
    opacity: 0.45 !important;
  }
`;
