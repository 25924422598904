import { init, setTag } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PublicShop } from '@typings/PublicShop';

function initialize(dsn: string, environment?: string) {
  init({
    dsn,
    environment,
    integrations: [new Integrations.BrowserTracing()],
  });
}

function setShop(shop: PublicShop) {
  setTag('shop_id', shop.id);
}

export const sentrySetup = {
  initialize,
  setShop,
};
