import React from 'react';
import styled from '@emotion/styled';

interface TermsOfServiceProps {
  shopName?: string;
}

const TermsOfService = ({ shopName }: TermsOfServiceProps) => (
  <TermsOfServiceContainer>
    {shopName && `${shopName}: `}You agree to receive subscription management messages and recurring
    automated text marketing. Purchase is not a condition of consent. Msg rates apply. Recharge Inc.{' '}
    <a href="https://rechargepayments.com/terms-of-service/" target="_blank" rel="noreferrer">
      T&C
    </a>
  </TermsOfServiceContainer>
);

const TermsOfServiceContainer = styled.p`
  margin-top: 1rem;
  color: var(--terms-of-service-font-color);
  font-size: 11px;
`;

export default TermsOfService;
