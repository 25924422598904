import React from 'react';
import styled from '@emotion/styled';

interface WavesProps {
  size?: number;
  status?: 'Offline' | 'Limited' | 'Online' | 'Optimal' | 'Update';
}

const bgColorsByStatus = {
  Offline: 'rgba(255, 173, 152, 0.42)',
  Limited: 'rgba(255, 228, 86, 0.42)',
  Online: 'rgba(173, 231, 225, 0.7)',
  Optimal: 'rgba(214, 202, 255, 0.42)',
  Update: 'rgba(98, 104, 243, 0.42)',
};

const waveColorsByStatus = {
  Offline: ['rgba(236, 61, 16, 0.52)'],
  Limited: ['rgba(255, 228, 86, 0.52)', 'rgba(255, 228, 86, 0.62)'],
  Online: ['rgba(23, 204, 157, 0.30)', 'rgba(23, 204, 157, 0.40)', 'rgba(23, 204, 157, 0.50)'],
  Optimal: ['rgba(214, 202, 255, 0.52)', 'rgba(214, 202, 255, 0.62)'],
  Update: ['rgba(98, 104, 243, 0.52)', 'rgba(98, 104, 243, 0.62)'],
};

const waveHeightsByStatus = {
  Offline: [18],
  Limited: [4, 10],
  Online: [4, 10, 20],
  Optimal: [4, 10],
  Update: [4, 10],
};

const waveFreqStatus = {
  Offline: 50,
  Limited: 75,
  Online: 100,
  Optimal: 125,
  Update: 125,
};

function Waves({ size = 126, status = 'Offline' }: WavesProps) {
  const bgColor = bgColorsByStatus[status];
  const colors = waveColorsByStatus[status];
  const heights = waveHeightsByStatus[status];
  const freq = waveFreqStatus[status];

  return (
    <WavesContainer color={bgColor} size={size}>
      <div className="inner-container">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 10 ${freq} 80`}
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            {colors.map((color, i) => (
              <use key={`wave-${i}`} xlinkHref="#gentle-wave" x="48" y={heights[i]} fill={color} />
            ))}
          </g>
        </svg>
      </div>
    </WavesContainer>
  );
}

export default Waves;

const WavesContainer = styled.div<{ color: string; size: number }>`
  background-color: #fff;
  border-radius: 50%;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  overflow: hidden;

  .inner-container {
    background: ${(props) => props.color};
    color: red;
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
  }

  .waves {
    position: relative;
    width: 100%;
    height: 100%;
  }
  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }

  .parallax > use:nth-of-type(1) {
    animation-delay: -2s;
    animation-duration: 8s;
  }

  .parallax > use:nth-of-type(2) {
    animation-delay: -7s;
    animation-duration: 11s;
  }

  .parallax > use:nth-of-type(3) {
    animation-delay: -14s;
    animation-duration: 15s;
  }

  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
`;
