import axios from 'axios';

export function verifySmsCode(shopId: string, params: any, smsCode: string): Promise<Response> {
  return axios.post(`/sms/opt-in/${shopId}/verify_sms_code`, {
    email: params.email,
    phone_number: `+${params?.phone}`,
    sms_code: smsCode,
  });
}

export function sendCode(
  shopifyDomain: string,
  phone: string,
  email: string,
  country: string
): Promise<Response> {
  return axios.post('/sms/phone_verification/send_code', {
    shop_domain: shopifyDomain,
    phone_number: `+${phone}`,
    country,
    email,
  });
}
