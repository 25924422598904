import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2';
import { useForm } from 'react-hook-form';

import { PublicShop } from '@typings/PublicShop';

import { PrimaryButton, AlertContainer } from '../components';
import TermsOfService from '../components/TermsOfService';
import { sendCode } from '../api';

interface InitialStepProps {
  ctaButtonLabel: string;
  shop: PublicShop;
  setOptInValues?: (values: { email: string; phone: string; country: string }) => void;
  setStep?: (step) => void;
}

export function InitialStep({ ctaButtonLabel, shop, setOptInValues, setStep }: InitialStepProps) {
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const formProps = useForm({
    defaultValues: {
      email: urlParams.get('email') || '',
      phone: '',
      country: 'us',
    },
  });
  const { register, handleSubmit, setValue, watch, getValues } = formProps;

  useEffect(() => {
    // Workaround to show placeholder when default country is selected
    // Set the input value to '' to show the placeholder at the start instead of +1, but keep the default country
    setTimeout(() => {
      const el = document.querySelector('#rsms-opt-in-widget-phone-input') as HTMLInputElement;
      if (el) {
        el.value = '';
      }
    }, 500);
  }, []);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      setOptInValues({ ...values, country: getValues().country });
      await sendCode(shop?.shopifyDomain, values.phone, values.email, 'us');
      setErrorAlert(null);
      setStep('verification_code');
    } catch (error) {
      if (error?.response?.data?.error) {
        setErrorAlert(error.response.data.error);
      } else {
        setErrorAlert('Failed to send verification code.');
      }
    } finally {
      setLoading(false);
    }
  };

  function handlePhoneChange(phone, country) {
    setValue('phone', phone);
    setValue('country', country?.countryCode);
  }

  return (
    <>
      {errorAlert && <AlertContainer>{errorAlert}</AlertContainer>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control
          type="email"
          placeholder="Enter valid email address"
          {...register('email', { required: true })}
        />
        <PhoneInput
          containerStyle={{ marginTop: '1rem' }}
          inputStyle={{ width: '100%', height: 50 }}
          country="us"
          onlyCountries={['us', 'ca']}
          inputProps={{
            id: 'rsms-opt-in-widget-phone-input',
            name: 'phone',
            required: true,
          }}
          value={watch('phone')}
          onChange={handlePhoneChange}
          disableDropdown
        />

        <PrimaryButton type="submit" block>
          {loading ? 'Sending...' : ctaButtonLabel}
        </PrimaryButton>

        <TermsOfService shopName={shop?.customName} />
      </Form>
    </>
  );
}
