import styled from '@emotion/styled';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

interface WrapperProps {
  ctaButtonColor: string;
  ctaButtonFontColor: string;
  termsOfServiceFontColor: string;
}

export const Wrapper = styled.div<WrapperProps>`
  --cta-button-color: ${(props) => props.ctaButtonColor};
  --cta-button-font-color: ${(props) => props.ctaButtonFontColor};
  --terms-of-service-font-color: ${(props) => props.termsOfServiceFontColor};

  input {
    border-color: #cacaca;
    border-radius: 5px;
    height: 50px;
  }
`;

const BaseButton = styled(Button)`
  border-color: var(--cta-button-color);
  background-color: var(--cta-button-color);
  color: var(--cta-button-font-color) !important;
  margin-top: 1rem;
  height: 50px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
`;

export const PrimaryButton = styled(BaseButton)`
  &:hover,
  &:active,
  &:focus {
    color: var(--cta-button-color) !important;
    border-color: var(--cta-button-color) !important;
    background-color: transparent !important;
  }
`;

export const SecondaryButton = styled(BaseButton)`
  color: #191d48 !important;
  border-color: #191d48 !important;
  background-color: transparent !important;
  position: relative;
  overflow: hidden;
  &:not(.fill):hover,
  &:not(.fill):active,
  &:not(.fill):focus {
    color: var(--cta-button-color) !important;
    border-color: var(--cta-button-color) !important;
  }
  &.fill:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(196, 196, 196, 0.6);
    position: absolute;
    transition: all 60s linear;
    top: 0;
    left: 0;
  }
  &.remove-fill:before {
    left: -100%;
  }
  &.disabled,
  [disabled] {
    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
    }
  }
`;

export const ThankYouContext = styled.div`
  color: #606270;
  font-size: 14px;
  font-weight: 500;
  margin: 20px auto;
  padding: 8px;
  text-align: center;
`;

export const ThankYouMessage = styled.div`
  color: #191d48;
  font-size: 14px;
  font-weight: 500;
  margin: 20px auto;
  padding: 18px;
  text-align: center;
  border: 2px solid #17cc9d;
  border-radius: 9px;
  background-color: #ebf9f7;
`;

export const CodeField = styled(Form.Control)`
  -moz-appearance: textfield;
  font-size: 32px;
  margin: 0;
  text-align: center;

  &::placeholder {
    text-align: center;
    font-size: 14px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const AlertContainer = styled.div`
  color: #606270;
  font-size: 12px;
  margin: 18px auto;
  text-align: center;
`;

export const OtpContainer = styled.div`
  > div {
    justify-content: space-between;
  }

  .inputStyle {
    width: 55px !important;
    height: 55px;
    font-size: 28px;
    font-weight: bold;
    border-radius: 9px;
    border: 2px solid #191d48;
    background-color: #f4f4f4;
    outline-color: var(--cta-button-color);
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .error {
    border: 2px solid #ec3d10 !important;
  }
`;
