import React from 'react';
import styled from '@emotion/styled';
import emailDotIcon from '@assets/icons/email-dot-icon.svg';

import { ThankYouMessage, ThankYouContext } from '../components';

interface ThankYouStepProps {
  email: string;
}

export const ThankYouStep = ({ email }: ThankYouStepProps) => (
  <div>
    <ThankYouContext>Can't find it? Check your spam folder!</ThankYouContext>
    <ThankYouMessage>
      <Icon src={emailDotIcon} />
      Verification email sent to {email}
    </ThankYouMessage>
  </div>
);

const Icon = styled.img`
  height: 22px;
  width: 26px;
  margin-bottom: 8px;
  margin-right: 8px;
`;
