import React, { useEffect, useState } from 'react';
import { sentrySetup } from '@config/sentry';
import { PublicShop } from '@typings/PublicShop';

import { verifySmsCode } from './api';
import { Wrapper } from './components';
import { InitialStep, Steps, ThankYouStep, VerificationCodeStep } from './steps';
import 'react-phone-input-2/lib/style.css';

interface OptInWidgetProps {
  shop?: PublicShop;
  ctaButtonColor?: string;
  ctaButtonFontColor?: string;
  ctaButtonLabel?: string;
}

export const OptInWidget = (props: OptInWidgetProps) => {
  const { shop } = props;
  const [step, setStep] = useState<Steps>('initial');
  // const [step, setStep] = useState<Steps>('verification_code');

  const [optInValues, setOptInValues] = useState({
    phone: '',
    email: '',
    country: '',
  });
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    sentrySetup.setShop(shop);
  }, [shop]);

  function getUrlParam(paramName, defaultParam, prefix = '') {
    if (props[paramName]) return props[paramName];

    const urlParam = urlParams.get(paramName);

    return urlParam ? `${prefix}${urlParam}` : defaultParam;
  }

  async function handleOptIn(smsCode) {
    return verifySmsCode(shop?.id, optInValues, smsCode);
  }

  const ctaButtonColor = getUrlParam('ctaButtonColor', '#3902f0', '#');
  const ctaButtonFontColor = getUrlParam('ctaButtonFontColor', '#ffffff', '#');
  const ctaButtonLabel = getUrlParam('ctaButtonLabel', 'Let’s do it 🙏');
  const termsOfServiceFontColor = getUrlParam('termsOfServiceFontColor', '#999', '#');

  const steps = {
    initial: (
      <InitialStep
        ctaButtonLabel={ctaButtonLabel}
        setOptInValues={setOptInValues}
        setStep={setStep}
        shop={shop}
      />
    ),
    verification_code: (
      <VerificationCodeStep
        shop={shop}
        optInValues={optInValues}
        setStep={setStep}
        onVerify={handleOptIn}
      />
    ),
    thank_you: <ThankYouStep email={optInValues.email} />,
  };

  return (
    <Wrapper
      ctaButtonColor={ctaButtonColor}
      ctaButtonFontColor={ctaButtonFontColor}
      termsOfServiceFontColor={termsOfServiceFontColor}
    >
      {steps[step]}
    </Wrapper>
  );
};
